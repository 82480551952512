// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Metropolis';
    font-weight: 900;
    src: url('assets/fonts/Metropolis-ExtraBold.woff');
}

@font-face {
    font-family: 'Metropolis';
    font-weight: 500;
    src: url('assets/fonts/Metropolis-Bold.woff');
}

@font-face {
    font-family: 'Metropolis';
    src: url('assets/fonts/Metropolis-Regular.woff');
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Metropolis',
  $headline-1: mat.define-typography-level(32px, 48px, 900),
  $body-1: mat.define-typography-level(16px, 24px, 100)
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($custom-typography);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();


$md-bommr-primary-palette: (
    50 : #e6edff,
    100 : #c0d1ff,
    200 : #96b3ff,
    300 : #6b95ff,
    400 : #4c7eff,
    500 : #2c67ff,
    600 : #275fff,
    700 : #2154ff,
    800 : #1b4aff,
    900 : #1039ff,
    A100 : #ffffff,
    A200 : #f8f9ff,
    A400 : #c5cdff,
    A700 : #acb7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-bommr-accent-palette: (
    50 : #fff5e8,
    100 : #ffe6c5,
    200 : #ffd69e,
    300 : #ffc677,
    400 : #ffb95a,
    500 : #ffad3d,
    600 : #ffa637,
    700 : #ff9c2f,
    800 : #ff9327,
    900 : #ff831a,
    A100 : #ffffff,
    A200 : #fffffe,
    A400 : #ffe1cb,
    A700 : #ffd3b2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Background palette for light themes.
mat.$light-theme-background-palette: (
  status-bar: map_get(mat.$grey-palette, 300),
  app-bar:    map_get(mat.$grey-palette, 100),
  background: map_get(mat.$grey-palette, 50),
  hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card:       white,
  dialog:     white,
  disabled-button: rgba(black, 0.12),
  raised-button: #e7ebf7,//white,
  focused-button: rgba(black, 0.12),
  selected-button: map_get(mat.$grey-palette, 300),
  selected-disabled-button: map_get(mat.$grey-palette, 400),
  disabled-button-toggle: map_get(mat.$grey-palette, 200),
  unselected-chip: map_get(mat.$grey-palette, 300),
  disabled-list-option: map_get(mat.$grey-palette, 200),
  tooltip: map_get(mat.$grey-palette, 700),
);


// Foreground palette for light themes.
mat.$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba(black, 0.26),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  hint-text:         rgba(black, 0.38),
  secondary-text:    rgba(black, 0.54),
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$boomr-primary: mat.define-palette($md-bommr-primary-palette);//mat-palette($mat-indigo);
$boomr-accent: mat.define-palette($md-bommr-accent-palette);

// The warn palette is optional (defaults to red).
$boomr-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$boomr-theme: mat.define-light-theme($boomr-primary, $boomr-accent, $boomr-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($boomr-theme);

/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: Metropolis, Roboto, "Helvetica Neue", sans-serif; }

button.mat-flat-button {
    border-radius: 7px;
    padding: 5px 19px;
}

button.mat-flat-button.cdk-focused {
    border: 2px;
    &:after {
        content: "";
        background: transparent;
        border: 2px solid #e7ebf7;
        position: absolute;
        border-radius: 9px;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
    }
}

button.mat-flat-button.mat-primary.cdk-focused {
    border: 2px;
    &:after {
        content: "";
        background: transparent;
        border: 2px solid #2c67ff;
        position: absolute;
        border-radius: 9px;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
    }
}

.mat-drawer-content {
    background: white;
}

.mat-form-field-wrapper {
    //padding: 0px;
}

.mat-form-field-appearance-outline .mat-select-trigger .mat-select-arrow-wrapper {
    transform: translateY(-25%) translateX(-10px);
}

.mat-select-trigger .mat-select-arrow {
    border: 1px solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    //color:
}

p {
    font-size: 16px;
}

.drugs-container {
    p {
        font-size: 18px;
    }
}

a {
    font-size: 14px;
    display: inline-block;
    color: #2c67ff;
    text-decoration: none;
}

.card-view {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100vh - 100px);
}

.card-view__container, .admin-form__container  {
    margin-top: 100px;
    width: 330px;
    box-shadow: 1px 2px 20px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    padding: 40px 50px;
    background: map_get(mat.$light-theme-background-palette, 'card');

    h1 {
        font-size: 24px;
        font-weight: 500;
        font-size: 26px;
    }

    .mat-form-field {
        width: 100%;

    }

    .mat-error p {
        text-align: center;
    }
    button {
        font-size: 18px;
        width: 100%;

        &.mat-flat-button.mat-primary[disabled] {
            background-color: #2c67ff;
        }
    }

    a {
        font-size: 14px;
        display: inline-block;
        color: #2c67ff;
        text-decoration: none;
        margin-top: 30px;
    }

    p {
        text-align: left;
        font-size: 14px;
        margin: 0px;
    }

    .mat-divider {
        margin: 35px 0;
    }

    .mat-checkbox {
        margin: 10px 20px;
        display: inline-block;
        margin: 10px 15px;

        a {
            display: inline;
        }

        &.mat-error {
            .mat-checkbox-frame {
                border-color: red;
            }
            a {
                color: red;
                text-decoration: underline;
            }
        }
    }
}

.card-view__subform {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: space-between;
}
.card-view__subform-group {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.admin-form__container {
  box-shadow: none;
  margin: 0;
  padding: 0;
  width: 100%;

  button {
    width: 200px;
    display: flex;
    justify-content: center;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: white;
}

mat-spinner {
    margin: 0 auto;
}

.mat-divider.main-divider {
    margin-bottom: 50px;
    margin-top: 7px;
}

.mat-card-transparent {
  background-color: rgba(map_get(mat.$light-theme-background-palette, 'card'), 95%) !important;
  backdrop-filter: blur(10px);
}

.mat-inline-form-fields {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 1rem;
}

.mat-tooltip {
  font-size: 1em;
}
